/**
  Add our adjusted gutter defaults for the mdc-layout-grid. The values are coming from our design team.
  We set them here to provide a consistent gutter size over all apps within the shell.
  --> If they do not set themselves a custom gutter size!
 */
$mdc-layout-grid-default-gutter: (
  desktop: 20px,
  tablet: 20px,
  phone: 6px,
);
@import '@material/layout-grid/mdc-layout-grid';

@import './constants';
@import './styles.colors';
@import './functions';

html {
  // width: 100vw can cause horizontal scroll bars, so we limit the width to max 100%
  width: 100vw;
  height: fullViewportHeight();
  max-width: 100%;
  background-color: $background-color;

  // TODO: consolidate this into one single modal dialog service to be used. We have both now as a fallback -> To be removed
  /*
    This class will be added to the html tag by DrkBlockScrollStrategy when MatDialog is opened from
    ModalDialogService used e.g. in customer-projects-app.
   */
  &.drk-modal-dialog-global-scrollblock {
    overflow: hidden !important;
  }

  &.cdk-global-scrollblock {
    /*
    Override the position here because otherwise there are layout issues on windows.
    If this override is not present the opening of MatDialog causes the body to change size and display
    scroll bars somehow. See https://github.com/angular/components/issues/7390

    Prevent scroll to top behaviour if MatDialog is shown and the viewport is scrolled and not at top
   */
    position: static !important;
    overflow: hidden !important;
  }

  /*
   * Make large dialogs scrollable.
   */
  .cdk-global-overlay-wrapper {
    overflow-y: auto !important;
  }
}

body {
  width: 100%;
  min-height: fullViewportHeight();
  margin: 0;
  overflow-y: auto;

  background-color: $background-color;
  -webkit-overflow-scrolling: touch;
}

/**
  EXPORTS
  Custom CSS variables are used to be able to use angular material defined colors etc.
  in the apps that are running in the shell via var(--variable-name).
  A polyfill is needed to support this feature for legacy browsers 'npm i css-vars-ponyfill'

  https://www.npmjs.com/package/css-vars-ponyfill
 */

//noinspection CssOverwrittenProperties
@mixin safeAreaInsets() {
  --safe-area-inset-top: 0px;
  --safe-area-inset-right: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-area-inset-left: 0px;
  --safe-area-inset-top: env(safe-area-inset-top, 0px);
  --safe-area-inset-right: env(safe-area-inset-right, 0px);
  --safe-area-inset-bottom: env(safe-area-inset-bottom, 0px);
  --safe-area-inset-left: env(safe-area-inset-left, 0px);
}

:root {
  --brand-primary-color: #{map-get($brand-primary-color, 500)};
  --brand-warn-color: #{map-get($brand-warning, 500)};
  --brand-accent-color: #{map-get($brand-secondary-color, 900)};
  --brand-light-blue-color: #{map-get($brand-primary-color, 300)};

  @include safeAreaInsets();

  --shell-footer-height: #{$footer-height};
  --shell-header-height: #{$header-height};
  --shell-header-footer-height: #{$header-footer-height};

  --shell-mdc-layout-grid-gap-desktop: #{map_get($mdc-layout-grid-default-gutter, desktop)};
  --shell-mdc-layout-grid-gap-tablet: #{map_get($mdc-layout-grid-default-gutter, tablet)};
  --shell-mdc-layout-grid-gap-phone: #{map_get($mdc-layout-grid-default-gutter, phone)};
}
