// Custom Theming for Angular Material
@use '@angular/material' as mat;
@use './material.typo.scss' as typo;
// For more information: https://material.angular.io/guide/theming
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');

@import '@material/shape/mixins';
@import '@material/shape/functions';
@import '@material/layout-grid/mdc-layout-grid';
@import './styles.colors.scss';

/*
 * theming
 */
$ci-app-primary: mat.m2-define-palette($brand-primary-color, 500);
$ci-app-accent: mat.m2-define-palette($brand-secondary-color, 500, 50, A400);
$ci-app-warn: mat.m2-define-palette($brand-warning);

// Create the theme object with all required properties in a single map structure.
$ci-app-theme: mat.m2-define-light-theme((
  color: (
    primary: $ci-app-primary,
    accent: $ci-app-accent,
    warn: $ci-app-warn
  ),
  typography: typo.$custom-typography,
  density: 0
));


@mixin custom-tooltip() {
  // Set Customer-Shell wide font-size for Mat-Tooltips
  .mat-mdc-tooltip {
    font-size: 12px;
  }

  .mat-mdc-tooltip-handset {
    font-size: 12px;
  }
}
