@use '@angular/material' as mat;
/*
 * Angular Material Typography Setup
 */

$font: '"Source Sans Pro", sans-serif';
$custom-typography: mat.m2-define-typography-config(
  $font-family: $font,
  $headline-1: mat.m2-define-typography-level(96px, 104px, 400, $letter-spacing: 1.5px),
  $headline-2: mat.m2-define-typography-level(60px, 68px, 400, $letter-spacing: 1.5px),
  $headline-3: mat.m2-define-typography-level(48px, 56px, 400, $letter-spacing: 0),
  $headline-4: mat.m2-define-typography-level(34px, 42px, 400, $letter-spacing: 0.25px),
  $headline-5: mat.m2-define-typography-level(24px, 32px, 300, $letter-spacing: 0),
  $headline-6: mat.m2-define-typography-level(20px, 28px, 600, $letter-spacing: 0),
  $subtitle-1: mat.m2-define-typography-level(16px, 24px, 600, $letter-spacing: 0.15px),
  $subtitle-2: mat.m2-define-typography-level(14px, 22px, 600, $letter-spacing: 0.1px),
  $body-1: mat.m2-define-typography-level(16px, 24px, 600, $letter-spacing: 0.5px),
  $body-2: mat.m2-define-typography-level(16px, 24px, 400, $letter-spacing: 0.25px),
  $caption: mat.m2-define-typography-level(12px, 20px, 400, $letter-spacing: 0.4px),
  $button: mat.m2-define-typography-level(14px, 22px, 400, $letter-spacing: 1.25px),
);
