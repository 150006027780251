/**
  This function will provide the correct height based on 100vh on all devices and also will take account for iOS
  safe-area-insets and fix the "double scroll" issue in iOS Safari

  If the browser does not support "safe-area-inset-top" or "safe-area-inset-bottom" this part of the term will be 0
  and the overall calc will be 100vh -> Will be the case on all browsers other than iOS Safari.

  If running on IE11 that does not support min() or env() we just take 100vh.
 */
@function fullViewportHeight() {
  @if function_exists(min) {
    @return min(100vh, calc(100vh - (var(--safe-area-inset-top) + var(--safe-area-inset-bottom))));
  } @else {
    @return 100vh;
  }
}
