// Custom Theming for Angular Material
@use '@angular/material' as mat;
@use './material.typo.scss' as typo;
// For more information: https://material.angular.io/guide/theming
@import '@material/shape/mixins';
@import '@material/shape/functions';
@import './styles.theme';
@import './constants';
@import '@material/layout-grid/mdc-layout-grid';
@import './styles.colors';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ci-app-theme);

// Include styles to be able to use .mat-typography class
@include mat.typography-hierarchy(typo.$custom-typography);

/*
  // Set font-size for all Mat-Tooltips used within the Customer-Shell //
  Needs to be added here, because "@include angular-material-typography($custom-typography);" sets the values
  already. We need to override them.
 */
@include custom-tooltip();

.page-mat-grid-content-inner mat-form-field {
  width: 100%;
  padding-bottom: 0;
}


/**
  Panel Classes

  They have to be inserted here even if they are used in customer apps because the panels are added to an
  material overly component and this is placed in window and not within the scope of the customer apps unfortunately
 */

// In customer-projects-app we use a year only date picker component. To hide other selection items we need this panel class
.c-year-only-date-picker-panel {
  .mat-mdc-calendar-period-button {
    pointer-events: none;
  }
  .mat-mdc-calendar-arrow {
    display: none;
  }
}

/**
  MatDialog overrides
 */

// Panel classes

// TODO: consolidate this into one single modal dialog service to be used. We have both now as a fallback -> To be removed

.c-modal-dialog {
  // We have to use !important here because even if we use this as "panelClass" when displaying MatDialog
  // width and max-width will be overriden by material design
  box-sizing: border-box !important;
  max-width: 800px !important;
  width: 90vw !important;
}

.c-projects-modal-dialog {
  // We have to use !important here because even if we use this as "panelClass" when displaying MatDialog
  // width and max-width will be overriden by material design
  box-sizing: border-box !important;
  max-width: 800px !important;
  width: 90vw !important;
  max-height: none !important;

  .mat-mdc-dialog-container {
    padding: 0 !important;
  }

  // drk-modal-dialog-* -> Classes added by ModalDialogService directives

  .drk-modal-dialog-header {
    position: relative;
    display: block;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .drk-modal-dialog-content {
    display: block;
    position: relative;
    overflow-y: auto;

    /**
    We use padding instead of margin for getting the desired space to header and footer to prevent appearing
    vertical scroll bar in some cases where content height is correct but cause of margin collapsing the scrollHeight
    is a little bit higher and thus a scrollbar is displayed
   */
    padding: 40px 0;
  }

  .drk-modal-dialog-footer {
    display: block;
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
  }
}

.mat-mdc-dialog-title {
  text-align: center;
}

.mat-mdc-dialog-container {
  background-color: $background-color;

  .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-underline::before {
    background-color: #{map-get($brand-primary-color, 500)};
  }

  .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-underline .mat-mdc-form-field-ripple {
    background-color: #{map-get($brand-primary-color, 500)};
  }

  .secondary-button {
    color: #{map-get($brand-primary-color, 500)} !important;
    box-sizing: border-box;
  }

  mat-dialog-actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  mat-dialog-actions > button:not(:last-child) {
    margin-right: 16px;
  }
}

/**
  MatExpansionPanels
 */

mat-expansion-panel-header .mat-mdc-content {
  height: 100% !important;
}

/**
  Snackbar
 */

.snackbar-info {
  background-color: map-get($brand-tertiary-color, 400);
  color: white;
}

.snackbar-success {
  background-color: map-get($brand-primary-color, 900);
  color: white;
}

.snackbar-warning {
  background-color: map-get($brand-warning, 900);
  color: white;
}

.snackbar-error {
  background-color: map-get($brand-secondary-color, 900);
  color: white;
  white-space: pre-wrap;
  .mat-mdc-simple-snackbar-action {
    color: #b45a65;
  }
}
.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-gap,
.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-start,
.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-outline-end {
  border-color: rgba(0, 0, 0, 0.38)!important;
}

.mat-mdc-form-field-appearance-outline.mat-mdc-form-field-can-float.mat-mdc-form-field-should-float .mat-mdc-form-field-outline-gap {
  border-top-color: rgba(0, 0, 0, 0)!important;
}

.mat-mdc-form-field-appearance-outline.mat-mdc-form-field-disabled .mat-mdc-form-field-outline-gap,
.mat-mdc-form-field-appearance-outline.mat-mdc-form-field-disabled .mat-mdc-form-field-outline-start,
.mat-mdc-form-field-appearance-outline.mat-mdc-form-field-disabled .mat-mdc-form-field-outline-end {
  border-color: rgba(0, 0, 0, 0.15)!important;
}

/**
  MatChip
  Set the font family for mat-chips to our Themes font family here, because it is not set when calling
  @include angular-material-typography($custom-typography).
 */
.mat-chip {
  font-family: #{typo.$font};
}

/**
  MatCheckbox
 */
.mat-checkbox-layout {
  // This needs to be overridden here with important to have checkbox labels not overflow their container but
  // instead wrap correctly to a new line
  white-space: normal !important;
}

/**
Override material css vars
 */
:root {
  --mat-option-label-text-weight: 400;
  --mat-option-label-text-tracking: normal;

  --mat-menu-item-label-text-weight: 400;
  --mat-menu-item-label-text-tracking: normal;

  --mat-select-trigger-text-weight: 400;
  --mat-select-trigger-text-tracking: normal;
}
